import { ref, computed } from '@vue/composition-api';
import { refResetter } from '@/hooks/utils';

const [localDatabaseInfo, resetLocalDatabaseInfo] = refResetter({
    name: 'LocalDatabase',
    application_id: null,
    module_id: null,
    action: '-- select action --',
    database: null,
    table: null,
    columns: [],
    duplicateVerifiers: [],
    query: {
        logicalOperator: 'all',
        children: [],
    },
    table_join_meta: {
        join_type: '',
        tables: [
            {
                table_id: '',
                join_on: '',
                position: 0
            }
        ]
    },
    shouldSortResult: false,
    sort_by: null,
    sort_direction: 'asc',
    shouldPaginateResult: false,
    limit: 10
});

const options = ref({
    actions: ['-- select action --', 'Read', 'Write', 'Update', 'Update on duplicate insert', 'Join'],
    databases: [],
    tables: [],
    columns: {},
    joinTypes: ['Inner Join', 'Left Join', 'Right Join']
});

export const useLocalDatabase = () => {
    const selectedTableColumns = computed(() => {
        if(localDatabaseInfo.value.table) {
            const columns = options.value.columns[localDatabaseInfo.value.table.TABLE_NAME]?.columns;
            if (columns) {
                return columns.map(column => ({
                    ...column,
                    table_name: localDatabaseInfo.value.table.TABLE_NAME
                }));
            }
            return [];
        }
        return [];
    });

    const joinedTableColumns = computed(() => {
        const joinedTables = localDatabaseInfo.value.table_join_meta.tables.map(table => table.table_id).filter(table => table);
        const columns = joinedTables.flatMap(table => {
            const tableColumns = (options.value.columns[table]?.columns || []).map(column => ({
                ...column,
                column_name: `${column.column_name}`,
                table_name: table,
                table_label: options.value.tables.find(t => t.TABLE_NAME === table).TABLE_LABEL
            }));
            return tableColumns;
        });
        return columns;
    });

    const isColumnPartOf = (columnId) => {
        return ['id', 'created_at', 'updated_at'].includes(columnId);
    };
    const isDefaultDateTypeColumn = (column) => {
        return (['created_at','updated_at'].includes(column.column_name) && column.column_type === 'DATETIME');
    };

    return {
        localDatabaseInfo,
        options,
        selectedTableColumns,
        joinedTableColumns,
        resetLocalDatabaseInfo,
        isColumnPartOf,
        isDefaultDateTypeColumn
    };
};
