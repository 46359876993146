<template>
  <div>
    <div
      v-if="!isModuleFetchDone"
      class="columns is-vcentered px-5"
    >
      <div class="is-10 column">
        <b-skeleton height="30" />
        <b-skeleton height="50" />
      </div>
      <div class="is-2 column">
        <b-skeleton height="30" />
      </div>
    </div>

    <NoResults
      v-else-if="modules.length === 0"
      banner-label="No modules yet, create one now."
      button-label="New Module"
      :button-handler="openNewModuleModal"
    />

    <b-table
      v-else
      :data="modules"
      class="tab-table-layout"
      @click="goToEditBuilder"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
        width="30%"
      >
        {{ row.name }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Modified"
        field="updated_at"
      >
        {{ row.updated_at }}
        <b-tooltip
          v-if="row.last_editor" 
          :label="row.last_editor"
        >
          <b-button
            size="is-small" 
            class="button disk disk-small is-primary"
          >
            {{ row.last_editor_initials }}
          </b-button>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="row.test_version"
        label="TEST"
      >
        <template
          v-slot:header="{ column }"
        >
          <b-tooltip
            label="Version deployed and running on test"
            append-to-body
            dashed
          >
            {{ column.label }}
          </b-tooltip>
        </template>

        <template v-slot="props">
          {{ props.row.test_version }}
        </template>
      </b-table-column>

      <b-table-column
        label="Production"
        field="prod_deployed_version"
      >
        <template
          v-slot:header="{ column }"
        >
          <b-tooltip
            label="Version deployed and running on production"
            append-to-body
            dashed
          >
            {{ column.label }}
          </b-tooltip>
        </template>
        <template
          v-slot="props">
          {{ props.row.prod_version }}
        </template>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Type"
        field="type"
      >
        {{ row.type }}
        <b-icon
          :icon="getModuleTypeIcon(row)"
          size="is-size-6"
          class="app-dropdown-icon"
        />
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleActionDropdown(row.id)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <b-dropdown
          :ref="`moduleActionDropdown${row.id}`"
          aria-role="list"
          position="is-bottom-left"
          class="app-action-dropdown"
          append-to-body
        >
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="openSideBarInfo(row)"
          >
            <b-icon
              icon="information-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Info
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="goToEditBuilder(row)"
          >
            <b-icon
              icon="pencil-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Edit
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="goToVersions(row)"
          >
            <b-icon
              icon="history"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Versions
          </b-dropdown-item>
          <b-dropdown-item
            v-if="row.type === 'Web app/Screens' || 'Web app/Auth'"
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="goToAnalytics(row)"
          >
            <b-icon
              icon="poll"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Analytics
          </b-dropdown-item>

          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="handleCopyingModule(row)"
          >
            <b-icon
              icon="content-copy"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Duplicate
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            custom
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <b-button
              icon-left="delete-outline"
              size="is-small"
              type="is-danger is-light"
              class="rounded-8 w-full"
              @click.stop="confirmDelete(row)"
            >
              Delete
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>
    <ConfirmDeleteModal
      v-model="isDeleteModuleModalVisible"
      :entity-name="moduleToBeDeleted.name"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Confirm Delete Module"
      aria-modal
      @delete="deleteModule()"
    >
      <template
        v-if="guardedModules.length > 0"
        #additional-message
      >
        <div
          class="is-flex is-align-items-center"
        >
          <b-icon
            size="is-medium"
            icon="alert-circle"
            type="is-danger"
            class="mr-4"
          />
          <div>
            <p class="is-size-6">
              Please note that the following modules are guarded by the module you are about to delete:
            </p>
            <ul style="list-style-type: disc; margin-left: 20px">
              <li
                v-for="guardedModule in guardedModules"
                :key="guardedModule.id"
              >
                <strong>{{ guardedModule.name }}</strong>
              </li>
            </ul>
            <p>If you delete <strong>{{ moduleToBeDeleted.name }}</strong>, the modules stated above will not be guarded anymore.</p>
          </div>
        </div>
      </template>
    </ConfirmDeleteModal>

    <b-sidebar
      v-model="isSidebarOpen"
      type="is-light"
      :fullheight="true"
      :right="true"
      :can-cancel="['escape', 'outside']"
    >
      <div>
        <p
          class="level-item"
        >
          <strong
            style="color: #5550FC;"
            class="has-text-centered"
          >{{ sidebarRowVal.name }}</strong>
        </p>
        <div class="sidebarlist has-text-centered">
          <p class="level-item">
            <strong>Created At</strong>
          </p>
          {{ sidebarRowVal.created_at }}
          <b-tooltip
            size="is-small" 
            :label="sidebarRowVal.author"
          >
            <b-button
              size="is-small" 
              class="button disk disk-small is-primary"
            >
              {{ sidebarRowVal.initials }}
            </b-button>
          </b-tooltip>
        </div>

        <div
          v-if="sidebarRowVal.prod_change_log"
          class="sidebarlist"
        >
          <p class="level-item">
            <strong>Prod change log</strong>
          </p>
          <p
            class="has-text-centered"
            v-html="sidebarRowVal.prod_change_log"
          />
        </div>
        <div
          v-if="sidebarRowVal.deployed_prod_id && sidebarRowVal.deployed_prod_url"
          class="sidebarlist"
        >
          <div
            style="display: flex;"
          >
            <!-- <b-button
              size="is-small"
              type="is-primary"
              label="Production URL"
              style="width: 100%"
              @click="goToExternalLink(sidebarRowVal.deployed_prod_url)"
            /> -->
            <p
              class="level-item"
              style="width: 90%"
            >
              <a
                :href="sidebarRowVal.deployed_prod_url"
                target="_blank"
                rel="noreferrer"
              >Production URL</a>
            </p>
            <div
              style="cursor: pointer;"
              @click="contentCopyToClipboard(sidebarRowVal.deployed_prod_url, 'production url.')"
            >
              <b-icon
                icon="content-copy"
                type="is-black"
                custom-size="mdi-22px"
                class="mr-2"
              />
            </div>
          </div>
        </div>
        <div
          v-if="sidebarRowVal.test_change_log"
          class="sidebarlist"
        >
          <p class="level-item">
            <strong>Test change log</strong>
          </p>
          <p
            class="has-text-centered"
            v-html="sidebarRowVal.test_change_log"
          />
        </div>
        <div
          v-if="sidebarRowVal.deployed_test_id && sidebarRowVal.deployed_test_url"
          class="sidebarlist"
        >
          <div
            style="display: flex;"
          >
            <!-- <b-button
              size="is-small"
              type="is-primary"
              label="Test URL"
              style="width: 100%"
              @click="goToExternalLink(sidebarRowVal.deployed_test_url)"
            /> -->
            <p
              class="level-item"
              style="width: 90%"
            >
              <a
                :href="sidebarRowVal.deployed_test_url"
                target="_blank"
                rel="noreferrer"
              >Test URL</a>
            </p>
            <div
              style="cursor: pointer;"
              @click="contentCopyToClipboard(sidebarRowVal.deployed_test_url, 'test url.')"
            >
              <b-icon
                icon="content-copy"
                type="is-black"
                custom-size="mdi-22px"
                class="mr-2"
              />
            </div>
          </div>
        </div>
        <div>
          <BaseSelect
            v-if="!isOffGuardModule"
            label="Auth Module"
            class="has-text-centered"
            :value="sidebarRowVal.guarded_module_id"
            @input="selectedAuthModule($event)"
          >
            <option
              class="has-text-centered"
              :value="null"
            >
              -- No Auth --
            </option>
            <option
              v-for="(authModule, idx) in authModules"
              :key="idx+'-'+authModule.id"
              :value="authModule.id"
              class="has-text-centered"
            >
              {{ authModule.name }}
            </option>
          </BaseSelect>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script >
import { defineAsyncComponent, getCurrentInstance, onMounted, reactive, ref, computed } from '@vue/composition-api';
import { fetchApplicationByIdService } from '@/services/application-service/applicationRequests';
import { copyModuleService, deleteModuleService, fetchModuleByIdService, fetchModulesService, guardModuleService, unguardModuleService } from '@/services/application-service/moduleRequests';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useApplicationStore } from '@/modules/builder/store/applicationStore';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { convertUTCToLocalTime, convertUTCToDesireTime, getFirstLetters, convertModuleVersionUTCToCestTime } from '@/helpers/util';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue'; // Services

import { getAuthModuleListService } from '@/services/application-service/module-auth/authModuleRequests'; // import ModuleInfoSidebar from '@/modules/builder/components/application/ModuleInfoSidebar.vue';
//-- child components --// 

const NoResults = defineAsyncComponent(() => import('@/modules/core/components/NoResults.vue'));
const ConfirmDeleteModal = defineAsyncComponent(() => import('@/modules/core/components/ConfirmDeleteModal.vue')); // @VUE3: do not use this function when migrating to vue 3

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance(); //-- composable hooks --//

  const moduleStore = useModuleStore();
  const applicationStore = useApplicationStore();
  const formBuilderStore = useFormBuilderStore();
  const moduleDeployStore = useModuleDeployStore();
  const router = useRouter();
  const route = useRoute();
  const buefy = useBuefy(); //-- modal logic --//

  const openNewModuleModal = () => {
    moduleStore.setModuleActivity(true);
  }; // auth module


  const additionalMessage = ref(null); //-- modules fetch logic --//

  const isModuleFetchDone = ref(false);
  const applicationName = ref('');
  const modules = ref([]);

  const fetchApplication = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchApplicationByIdService(route.params.appId);
      applicationName.value = data.name;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchModules = async () => {
    try {
      let {
        data: {
          data
        }
      } = await fetchModulesService(route.params.appId);
      data = data.map(module => {
        if (module.last_deployed_version && module.last_deployed_version === module.prev_deployed_version) {
          module.prod_test_env_is_even = true;
        } else {
          module.prod_test_env_is_even = false;
        }

        if (module.deployed_env === 'test') {
          module.deployed_prod_id = module.prev_deployment_id;
          module.deployed_test_id = module.deployment_id;
          module.test_version = module.last_deployed_version;
          module.prod_version = module.prev_deployed_version;
          module.test_change_log = module.change_log;
          module.prod_change_log = module.prev_change_log;
        } else if (module.deployed_env === 'production') {
          module.deployed_prod_id = module.deployment_id;
          module.deployed_test_id = module.prev_deployment_id;
          module.test_version = module.prev_deployed_version;
          module.prod_version = module.last_deployed_version;
          module.test_change_log = module.prev_change_log;
          module.prod_change_log = module.change_log;
        } else {
          module.deployed_test_id = null;
          module.deployed_prod_id = null;
          module.test_version = null;
          module.prod_version = null;
          module.test_change_log = null;
          module.prod_change_log = null;
        }

        return { ...module,
          created_at: convertUTCToLocalTime(module.created_at),
          updated_at: convertUTCToDesireTime(module.updated_at),
          last_editor_initials: getFirstLetters(module.last_editor),
          deployed_env: module.deployed_env ? module.deployed_env.toUpperCase() : 'N/A',
          last_deployed_version: module.last_deployed_version ? module.last_deployed_version : 'N/A',
          deployed_at: module.deployed_at ? convertUTCToLocalTime(module.deployed_at) : 'N/A',
          test_version: module.test_version ? convertModuleVersionUTCToCestTime(module.test_version) : 'N/A',
          prod_version: module.prod_version ? convertModuleVersionUTCToCestTime(module.prod_version) : 'N/A',
          deployed_prod_url: moduleDeployStore.deploymentUrls[module.moduleTypeId] ? moduleDeployStore.deploymentUrls[module.moduleTypeId] + module.deployed_prod_id : null,
          deployed_test_url: moduleDeployStore.deploymentUrls[module.moduleTypeId] ? moduleDeployStore.deploymentUrls[module.moduleTypeId] + module.deployed_test_id : null
        };
      });
      modules.value = data;
      isModuleFetchDone.value = true;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAuthModules = async () => {
    const authModule = await getAuthModuleListService(route.params.appId);
    authModules.value = authModule.data.data;
  };

  onMounted(() => {
    fetchModules();
    fetchApplication();
    fetchAuthModules();
  }); //-- module copy logic --//

  /**
   * @param {import('./types/modulesList').IModuleItem} tableRow
   */

  const handleCopyingModule = async tableRow => {
    try {
      const {
        id
      } = tableRow;
      const {
        data: {
          data
        }
      } = await fetchModuleByIdService(route.params.appId, id);
      buefy.dialog.prompt({
        message: 'Name of the new module',
        inputAttrs: {
          placeholder: 'e.g. My new copied module',
          value: 'Copy of ' + data.name
        },
        trapFocus: true,
        onConfirm: name => {
          copyModule(tableRow, name);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * @param {import('./types/modulesList').IModuleItem} tableRow
   * @param {string} name 
   */


  const copyModule = async (tableRow, name) => {
    try {
      await copyModuleService(route.params.appId, tableRow.id, {
        name
      });
      fetchModules();
      buefy.toast.open('Module copied.');
    } catch (err) {
      console.error(err);
    }
  }; //-- module deletion logic --//


  const isDeleteModuleModalVisible = ref(false);
  const moduleToBeDeleted = reactive({
    id: '',
    name: '',
    type: ''
  });
  const guardedModules = ref([]);
  /**
   * @param {import('./types/modulesList').IModuleItem} item 
   */

  const confirmDelete = async item => {
    const {
      data: {
        data: {
          guarded_modules
        }
      }
    } = await fetchModuleByIdService(item.application_id, item.id);
    guardedModules.value = guarded_modules?.idNames || [];
    moduleToBeDeleted.id = item.id;
    moduleToBeDeleted.name = item.name;
    moduleToBeDeleted.type = item.type;
    toggleActionDropdown(item.id);
    isDeleteModuleModalVisible.value = true;
  };

  const deleteModule = async () => {
    try {
      await deleteModuleService(route.params.appId, moduleToBeDeleted.id);
      moduleToBeDeleted.id = '';
      moduleToBeDeleted.name = '';
      moduleToBeDeleted.type = '';
      isDeleteModuleModalVisible.value = false;
      additionalMessage.value = null;
      guardedModules.value = [];
      buefy.toast.open('Module deleted!');
      await fetchModules();
    } catch (err) {
      console.error(err);
    }
  }; //-- module table actions logic --//
  // Sidebar


  const isSidebarOpen = ref(false);
  const sidebarRowVal = ref({});
  const authModules = ref([]);

  const openSideBarInfo = tableRow => {
    isSidebarOpen.value = true;
    sidebarRowVal.value = tableRow;
  };

  const goToExternalLink = link => {
    window.open(link, '_blank');
  };

  const contentCopyToClipboard = async (text, message) => {
    try {
      await navigator.clipboard.writeText(text);
      /* clipboard successfully set */

      buefy.toast.open({
        message: `Copied ${message}`,
        type: 'is-success'
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const selectedAuthModule = async guarded_module_id => {
    // find module object in modules
    const moduleIdx = modules.value.findIndex(v => v.id === sidebarRowVal.value.id);

    if (guarded_module_id) {
      // guard
      const payload = {
        guarded_module_id
      };
      const guardedData = await guardModuleService(route.params.appId, sidebarRowVal.value.id, payload);
      modules.value[moduleIdx].guarded_module_id = guardedData.data.data.guarded_module_id;
    } else {
      // unguarded
      const payload = {
        guarded_module_id: sidebarRowVal.value.guarded_module_id
      };
      await unguardModuleService(route.params.appId, sidebarRowVal.value.id, payload);
      modules.value[moduleIdx].guarded_module_id = null;
    }
  };

  const getModuleTypeIcon = row => {
    if (row.type === 'Web app/Screens' && row.guarded_module_id) {
      return 'lock';
    } else if (row.type === 'Web app/Auth') {
      return 'shield-key';
    } else if (row.type === 'Web app/Screens' && !row.guarded_module_id) {
      return 'lock-open-variant';
    } else {
      return '';
    }
  };

  const isOffGuardModule = computed(() => sidebarRowVal.value?.type === 'Web app/Auth' || sidebarRowVal.value?.type === 'Background job' || sidebarRowVal.value?.type === 'API');
  /**
   * @param {import('./types/modulesList').IModuleItem} tableRow
   */

  const goToEditBuilder = tableRow => {
    const {
      id
    } = tableRow;
    applicationStore.reset();
    formBuilderStore.reset();
    router.push('/application/' + route.params.appId + '/module/' + id + '/edit');
  };
  /**
   * @param {import('./types/modulesList').IModuleItem} tableRow
   */


  const goToVersions = tableRow => {
    router.push('/application/' + route.params.appId + '/module/' + tableRow.id + '/versions');
  };

  const goToAnalytics = tableRow => {
    router.push('/analytics');
  };
  /**
   * @param {string} rowId
   */


  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`moduleActionDropdown${rowId}`].toggle();
  };

  return {
    openNewModuleModal,
    isModuleFetchDone,
    modules,
    handleCopyingModule,
    isDeleteModuleModalVisible,
    moduleToBeDeleted,
    guardedModules,
    confirmDelete,
    deleteModule,
    isSidebarOpen,
    sidebarRowVal,
    authModules,
    openSideBarInfo,
    contentCopyToClipboard,
    selectedAuthModule,
    getModuleTypeIcon,
    isOffGuardModule,
    goToEditBuilder,
    goToVersions,
    goToAnalytics,
    toggleActionDropdown
  };
};

__sfc_main.components = Object.assign({
  NoResults,
  ConfirmDeleteModal,
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.list:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
@import '~@/style/utilities.scss';
.sidebarlist {
  padding: 6px 10px;
  border: 3px solid rgba(231, 228, 228, 0.832);
  margin: 10px auto;
}
</style>
